import React from "react";
import "./Header.css";
import { Link } from "gatsby";

const Header = () => {
  return (
    <>
      <div className="title-wrapper">
        <Link to="/">
          <h1>Adventi kalendárium</h1>
        </Link>
      </div>
    </>
  );
};

export default Header;
