import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.locale("hu");
dayjs.extend(isSameOrBefore);

export const isDisabled = (monthDayString) => {
  const today = dayjs();
  const monthDayDate = dayjs(`2021-${monthDayString}`);
  return monthDayDate.isSameOrBefore(today);
};
